@import "globals.scss";
.sponsor-cnt {
  .sc-add-sponsor,
  .save-btn {
    padding: 6px 2.5rem;
  }

  #add-sponsor {
    padding: 0;
    vertical-align: middle;
    margin-left: 0.5rem;

    svg {
      fill: var(--GreenRemo);
    }
  }

  .sponsors-list {
    border: 1px solid #f2f2f2;
    border-radius: 10px;
    padding: 0 1.25rem;

    li {
      padding-bottom: 1rem;
      padding-top: 1rem;

      .MuiListItem-root {
        .MuiAvatar-img {
          object-fit: contain;
          border: 1px solid #e6e6e6;
          border-radius: 5px;
          box-sizing: border-box;
        }

        &.max {
          opacity: 0.5;

          & + .actions {
            opacity: 0.5;
          }
        }

        .MuiAvatar-root {
          border-radius: 0;
          width: 4rem;
          height: 3rem;
        }

        .MuiListItemText-root {
          padding-left: 30px;
          margin: 0;

          .MuiTypography-h5 {
            padding-bottom: 0.5rem;
            font-size: 1rem !important;
            font-weight: 500 !important;
          }
        }
      }

      .actions {
        right: 0.5rem;

        div {
          display: inline-block;
        }
      }
    }
  }

  .sponsor-form {
    padding-bottom: 30px;
    position: relative;

    .sup-wrap {
      margin-top: 10px;
    }

    .sponsor-grid {
      margin-bottom: 1.5rem;
      margin-top: 1.25rem;

      .right {
        margin-top: 6px;

        .icon-btn-with-tooltip svg {
          fill: var(--GreenRemo);
          font-size: 0.875rem;
        }

        #sponsor-logo-help,
        #sponsor-slot-help {
          padding: 0 6px;
        }

        .sponsor-slot-title {
          display: flex;
          justify-content: space-between;
        }
      }

      .theme-view {
        margin-top: 0.75rem;
      }

      .logo-sec {
        h6 {
          font-weight: normal;
          margin-bottom: 1.5rem;
        }

        .MuiAvatar-root {
          width: 166px;
          height: 100px;
          border-radius: 6px;
          border: solid 2px var(--LightGrey);
          background-color: var(--VeryLightGrey);
          margin-right: 1.5rem;

          svg {
            fill: var(--LightGrey);
          }

          img {
            object-fit: contain;
          }
        }
      }

      .upload {
        position: relative;
        background: #fff;
        margin-bottom: 34px;
        margin-top: 18px;
        display: flex;

        > div {
          margin: 0;

          label {
            transform: translate(14px, 11px) scale(1);
          }

          input {
            padding: 6.5px 14px;
          }
        }

        button {
          border-width: 2px;
          padding: 6.5px 15px;
          border-color: var(--GreenRemo);
          color: var(--GreenRemo);
          position: absolute;
          right: 0;
          z-index: 0;

          &:hover {
            background: #fff;
          }
        }
      }

      .MuiFormControlLabel-root {
        &.radio-quare {
          width: 3.125rem;
          height: 3.125rem;
          margin-right: 1.25rem;
          margin-bottom: 1rem;
          border-radius: 5px;
          border: solid 2px #c4c4c4;
          background-color: #ffffff;
          position: relative;
          margin-left: 0;
          justify-content: center;
          box-sizing: border-box;

          .MuiIconButton-root {
            opacity: 0;
          }

          .MuiFormControlLabel-label {
            font-size: 1.125rem;
          }

          &:hover,
          &.checked {
            border-color: var(--GreenRemo);
            border-width: 2px;
            background-color: var(--GreenRemo);

            .MuiFormControlLabel-label {
              color: #ffffff;
            }
          }

          &.disabled {
            background-color: var(--VeryLightGrey);
            border: none;
          }

          svg {
            font-size: 1rem;
          }

          .MuiRadio-root {
            position: absolute;
            top: -6px;
            right: -7px;
            padding: 0;
            background-color: #fff;
            display: none;

            &.Mui-checked {
              display: block;
            }
          }
        }

        .MuiRadio-root {
          padding: 4px;
        }
      }

      .ad-media-view {
        div {
          width: 435px;
          height: 298px;
          display: flex;
          border: solid 2px var(--LightGrey);
          border-radius: 4px;
          background-color: var(--VeryLightGrey);
          box-sizing: border-box;
          justify-content: center;
          align-items: center;

          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
            border-radius: 4px;
            border: solid 2px var(--LightGrey);
          }

          svg {
            font-size: 3.5rem;
            fill: var(--LightGrey);
          }
        }
      }

      .map-image {
        height: 312.6px !important;
      }

      .cta-button-container {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        padding: 10px 0;
      }
    }
  }
}
