@import "globals.scss";
.root {
  margin: 1rem !important;
  border-radius: 10px;
  width: 690px !important;
  height: 318px;
  border: 2px dashed #235efb;
  background-color: #f0fcff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.upload-label {
  height: 32px;
  justify-content: center;
  align-items: center;
  display: flex;
  padding: 10px 15px;
  border-radius: 5px;
  background-color: #235efb;
  width: 136px;
  max-width: 136px;
  color: #fff;
  font-weight: 500;
  text-align: center;
  margin: auto;

  &:active {
    background-color: #deeaff;
    border-color: #deeaff;
    color: #235efb;
  }

  &:hover {
    cursor: pointer;
    background-color: #1482e3;
  }

  &.loading {
    cursor: not-allowed;
    background-color: #bdc3c7;
    color: #000;
  }
}

.upload-input {
  display: none;
}

.drop-svg-copy {
  font-size: 0.7rem;
  color: #556176;
  text-align: center;
  font-weight: 600;
}
