@import "globals.scss";
.input-group {
  width: 100% !important;

  > .color-picker {
    border: 1px solid black;
    border-radius: 5px;
    border-color: #556176;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 180px;
    background-color: #ffffff;
    position: relative;
    padding-left: 1rem;
    top: 19px;
    cursor: pointer;

    > input {
      width: 23px;
      margin: 10px;
      position: absolute;
      left: 0;
      cursor: pointer;
    }

    > .color-picker-label {
      position: absolute;
      top: -23px;
      left: 0;
      color: #0e1f3c !important;
      font-weight: 500;
    }
  }
}

.input-group-buttons {
  position: relative;
  top: 420px;

  &-save {
    margin-right: 10px !important;
  }

  &-delete {
    width: 40px;
  }
}

.floorplan-name {
  min-width: 250px;

  &.error {
    border: 2px solid red;
  }
}

.tags-box {
  max-width: 300px;
  width: 100%;
}
