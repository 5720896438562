@import "globals.scss";
.video-player-iframe-ctn {
  width: 100%;
  height: 100%;

  iframe,
  > div {
    width: 100%;
    height: 100%;
    border: none;
  }
}
