@import "globals.scss";
@import "~filepond/dist/filepond.min.css";

.tr-circle,
.tr-profile .avatar-component {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.tr-profile .avatar-wrapper {
  cursor: pointer;
  width: 100%;
  height: 100%;

  > span {
    width: 100%;
    height: 100%;

    .avatar-initial {
      font-size: 2.25rem;

      @media screen and (max-width: $screen-md-min) {
        font-size: 1.5rem;
      }
    }
  }
}

.connectivity-status-container + .tr-profile .avatar-wrapper {
  .avatar-initial {
    font-size: 2.5rem;
  }
}

/* scrollbar style */
/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* video */
video {
  max-width: unset;
  object-fit: contain;
  background-color: black;
}

.notifications-wrapper {
  .notification {
    padding: 12px !important;
    border-left: 8px solid #19692c !important;
    background-color: #28a745 !important;
    color: #fff !important;
  }

  .notification-message {
    white-space: pre-line;
  }

  .notification-title {
    color: #fff !important;
  }

  .notification-action-wrapper {
    text-align: right;
  }

  .notification-info {
    .notification-action-button {
      background: var(--GreenRemo) !important;
      cursor: pointer;
    }
  }

  .notification-dismiss {
    background-color: transparent !important;
    color: #fff !important;
  }

  .notification-error {
    border-left: 8px solid #a71d2a !important;
    background-color: #dc3545 !important;
  }

  .notification-warning {
    border-left: 8px solid #9e7600 !important;
    background-color: #eab000 !important;
  }

  .notification-info {
    border-left: 8px solid #0f6674 !important;
    background-color: #17a2b8 !important;
  }
}

.ra-avatar .ra-av-chat {
  display: none;
}

.ra-avatar:hover .ra-av-chat {
  display: inline-block;
  cursor: pointer;
}

.file-uploader-container {
  position: fixed;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  width: 380px;
  z-index: $fileUploaderZIndex;

  @include xs {
    top: 4rem;
    max-width: 100vw;
  }
}

.file-uploader-container input {
  visibility: hidden;
}

.filepond--panel-root {
  background: transparent !important;
}

.filepond--root {
  margin-bottom: 0;
}

.filepond--root .filepond--drop-label {
  display: none;
}

.filepond--file-action-button.filepond--file-action-button span {
  display: none;
}

.hlp-launcher-button {
  right: -60px !important;
}

.dialog-big {
  padding: 40px 90px 10px;
  width: 360px;

  .MuiDialogContent-root {
    padding: 0 !important;

    h3 {
      margin-bottom: 16px;
    }
  }

  .MuiDialogActions-root {
    padding: 20px 0;
    justify-content: space-between;
    display: flex;

    button {
      width: 166px;
    }
  }
}

div[class~="WAMuiChipInput-root"] {
  display: inline-block;

  div[class~="WAMuiChipInput-inputRoot"] {
    padding: 5px 10px;
  }
}

.close-btn.MuiButton-root {
  min-width: 0;
  width: 2.25rem;
  height: 2.25rem;
  background: #eee;
  box-sizing: border-box;
  color: #7b7b7b;

  .MuiSvgIcon-root {
    width: 2rem;
  }
}

.MuiTooltip-tooltip {
  text-align: center;
}

.rc-shadow {
  box-shadow: -2px 2px 20px #0000001a;
}

.remo-hover {
  position: relative;
  cursor: pointer;
  z-index: $remoHoverZIndex;

  &::before {
    content: "";
    position: absolute;
    inset: 0;
    background-color: #efefef;
    opacity: 0;
    transform: scale(0.8);
    transform-origin: center;
    transition: opacity 0.2s ease-in-out, transform 0.2s ease-in-out;
    z-index: $remoHoverBackgroundZIndex;
  }

  &-active::before {
    opacity: 1;
    transform: scale(0.9);
  }

  @media (hover: hover) {
    &:hover::before {
      opacity: 1;
      transform: scale(0.9);
    }
  }

  &-br-10::before {
    border-radius: 10px;
  }
}

.remo-hover-full {
  @media (hover: hover) {
    &:hover::before {
      transform: scale(1);
    }
  }
}

.MuiButton-label {
  transform: translate(0);
}

.flex-row-center {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

.filepond--root .filepond--credits {
  display: none;
}
