/*
Min and max resolutions
iOS
320 - 568 to 375 - 812
Android
320 - 640 to 412 - 846
*/
// IMPORTANT: to be in sync with theme.ts and useMediaQueryUtils.ts for MUI components
// mobiles portrait view
$screen-xs-max: 639.95px;
$screen-md-min: 640px;
$screen-sm-min: 320px;

// mobiles landscape view
$screen-xs-ls-max: 845.95px;
$screen-md-ls-min: 846px;
$screen-md-ls-max: 1024px;

// Small tablets and large smartphones (landscape view)
$screen-sm-max: 719.95px;

// Small tablets (portrait view)
$screen-md-max: 959.95px;

// mobiles
@mixin xs {
  // Portrait max width upto screen-xs-max OR landscape with max width screen-xs-ls-max
  @media (max-width: #{$screen-xs-max}), (max-width: #{$screen-xs-ls-max}) and (orientation: landscape) {
    @content;
  }
}

// Small tablets and large smartphones (landscape view)
@mixin sm {
  @media (max-width: #{$screen-sm-max}) {
    @content;
  }
}

// Medium devices
@mixin md {
  @media (min-width: #{$screen-md-min}), (min-width: #{$screen-md-ls-min}) and (orientation: landscape) {
    @content;
  }
}

@mixin md-only {
  /* stylelint-disable-next-line max-line-length */
  @media (min-width: #{$screen-md-min}) and (max-width: #{$screen-md-max}), (min-width: #{$screen-md-ls-min}) and (orientation: landscape) and (max-width: #{$screen-md-ls-max}) {
    @content;
  }
}

@mixin scroll-dark {
  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-track {
    background: var(--White-op-030);
    border-radius: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background: #45475e;
    border-radius: 5px;
  }

  scrollbar-color: #45475e var(--White-op-030);
}
