@import "globals.scss";
.svg-container {
  margin-right: 24px;
  position: absolute;
  transform-origin: left top;

  &.fullscreen {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
  }

  .svg-wrap {
    display: flex;
    justify-content: center;
    position: relative;
    border-radius: 10px;
    border: 1px solid #eaeef2;
    width: 100%;
    height: 100%;

    > svg {
      width: 100%;
      height: 100%;
    }

    &.fullscreen {
      position: absolute;
      display: flex;
      justify-content: flex-start;
      width: 100%;
      height: 100%;

      > svg {
        width: 100%;
        height: 100%;
      }
    }
  }

  &.parsing {
    .svg-wrap {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      opacity: 0;
    }
  }

  .close-btn {
    position: absolute;
    z-index: 2;
    transform: scale(2.5);
    transform-origin: top right;
    right: 2rem;
    top: 2rem;
  }

  .fullscreen-btn {
    position: absolute;
    bottom: 0.75rem;
    right: 0.75rem;
    z-index: 2;
    background-color: white !important;

    &-text {
      display: flex;
      align-items: center;
      line-height: 1;
      margin-right: 1rem;
    }
  }

  .svg-config-wrap {
    width: 100%;
    height: 100%;
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;

    .svg-conf-area {
      position: absolute;
      background: rgb(255 0 0 / 15%);
      border: 2px solid red;
    }

    .room {
      display: inline-block;
      position: absolute;
      background: rgb(0 0 0 / 60%);
      color: #000;
      text-align: center;

      &.isometric {
        background: transparent;
      }

      &.blocked {
        background-color: #e9be25;
        animation: flickerAnimation 3s infinite;
      }

      .seat {
        position: absolute;
        border-radius: 50%;
        background: rgb(0 0 0 / 40%);
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;

        &.admin {
          b {
            background: url("../svgMapView/assets/admin-seat-icon.svg")
              no-repeat center;
            height: 12px;
            width: 12px;
            display: inline-block;
            background-size: contain;
            opacity: 0.8;
          }
        }
      }

      .room-name {
        color: rgb(136 104 104);
        font-size: 16px;
        text-align: center;
        width: 100%;
        z-index: 2;
        position: absolute;
      }

      .text-path-name-container {
        top: 0;
        width: 100%;
        height: 100%;
      }

      .room-position {
        position: absolute;
        display: flex;
        align-items: center;
        top: 0;
        left: 0;
        min-width: 1.5rem;
        min-height: 1.5rem;
        background: blue;
        color: #fff;
        padding: 0.5rem;
        height: 0.5rem;
        font-size: 1.5rem;

        &.blocked {
          background-color: red;
        }

        > .icon {
          margin-left: 4px;
        }

        &.special {
          background-color: yellow;
          color: #666;
          padding: 0 8px;
        }

        &.isometric {
          top: calc(50% - 13px);
          left: calc(50% - 28px);
        }
      }
    }

    .sponsor {
      position: absolute;
      background: rgb(98 98 98 / 40%);
      text-align: center;
      font-size: 30px;
    }
  }
}

.MuiGrid-root {
  .MuiPaper-elevation1 {
    box-shadow: none;
  }
}

.cfp-results-grid-root {
  height: 100%;
  overflow-y: auto;
  z-index: 1;
  min-width: 20rem;
  border: 1px solid #eaeef2;
  border-radius: 10px;

  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-track {
    background: var(--White-op-030);
    border-radius: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background: #c4c4c4;
    border-radius: 5px;
  }
}

/* stylelint-disable-next-line keyframes-name-pattern */
@keyframes flickerAnimation {
  0% {
    opacity: 0.85;
  }

  50% {
    opacity: 0.4;
  }

  100% {
    opacity: 0.85;
  }
}
