@import "globals.scss";
.cfp-container {
  // needed because overflow was requested at a child component down the tree
  // (within validator results, CustomFloorPlanResults).
  // if there's any suggestions on how to properly cascade proper parent element height, will update
  height: calc(100% - 62px) !important;
  margin: 0 auto;
  padding: 2rem 1rem;

  .header-text {
    font-weight: 500 !important;
  }

  .fullscreen-overlay {
    position: fixed;
    width: 100vw;
    height: 100vh;
    inset: 0px;
    background-color: rgb(0 0 0 / 50%);
    z-index: 2;
  }

  .MuiTypography-gutterBottom {
    margin-bottom: 2rem;
  }
}

.cfp-validator-v2 {
  width: 100%;
  height: 100%;
  z-index: 2;
  overflow-y: auto;

  &::-webkit-scrollbar {
    display: none;
  }

  &-results-ctn {
    height: 100%;
  }
}
