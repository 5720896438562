@import "globals.scss";
.features-page-ctn {
  .MuiFormControlLabel-labelPlacementStart {
    margin-left: 0;
  }

  .feature-label {
    white-space: nowrap;
  }

  .beta-feature {
    margin-right: 5px;
    margin-left: 0 !important;
  }
}
