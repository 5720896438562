@import "globals.scss";
.manage-event-modal {
  .dialog-title {
    box-shadow: 0 1px 0 0 #e3e3e3;
    padding: 12px 24px;
  }

  .title-container {
    margin: 0 auto;
    display: flex;
    align-items: center;

    .title {
      line-height: 2.25;
      font-size: 1.125rem;
      padding-left: 1.125rem;
    }
  }

  .manage-event-actions {
    display: flex;
    position: absolute;
    align-items: center;
    right: 32px;
    height: inherit;

    @include xs {
      right: 15px;
    }

    .profile-picture {
      width: 40px;
      height: 40px;
    }
  }
}
