@import "globals.scss";
.eah-scroller {
  display: flex;
  flex: 1 0 80%;

  .left,
  .right {
    padding: 0;
    border-radius: 0;
    color: var(--EventsBlueSecondary);

    &:hover {
      // background-color: transparent;
    }
  }

  .eah-items {
   
    overflow: hidden;

    > div {
      display: -webkit-inline-box;
      transition: transform 300ms ease-in-out;

      > div {
        padding: 0 16px;
        color: var(--LightGrey);
        text-align: center;
        line-height: 2.75rem;
        font-size: 14px;
        font-weight: 700;
        margin-right: 8px;
        cursor: pointer;

        &:hover,
        &.active {
          background-color: var(--EventsBlueSecondary);
          color: var(--White);
          border-radius: 4px;
        }
      }
    }
  }

  &.darkBlue {
    button {
      color: rgb(255 255 255 / 100%);

      &.Mui-disabled {
        color: rgb(255 255 255 / 70%);
      }
    }

    .eah-items > div > div {
      color: rgb(255 255 255 / 90%) !important;

      &:hover {
        background-color: #484a56 !important;
      }
    }

    .active {
      background-color: #484a56 !important;
    }
  }
}
