@import "globals.scss";
.uploader-dialog-title {
  margin: 0;
  padding: 1rem;
}

.uploader-close-btn {
  position: absolute !important;
  top: 1rem;
  right: 1rem;
  z-index: 2;
}

.uploader-dialog-content {
  height: 100%;
  overflow: hidden;
  padding: 5% !important;
}

.uploader-dialog-cfp-container {
  height: 100%;
  max-width: 50rem;
  margin: auto;
}
