@import "globals.scss";
.me-leftmenu {
  height: 100%;
  overflow-y: auto;
  padding-top: 32px;
  background-color: var(--EventsBlueSecondary);

  &.MuiList-root {
    min-width: 200px;
    border-right: 3px solid #fafafa;
    box-sizing: border-box;
  }

  .MuiDivider-root {
    margin-top: 10px;
  }

  .MuiListItem-root {
    transition: opacity ease-in-out 100ms;
    padding: 12px 36px;

    .MuiTypography-body1 {
      font-weight: 500;
      font-size: 16px;
      color: var(--White);
    }

    &:hover {
      background-color: var(--EventsBlueAccent2);
    }
  }

  .MuiCollapse-container {
    .MuiListItem-root {
      margin-bottom: 0;

      .MuiTypography-body1 {
        font-size: 0.875rem;
      }
    }
  }
}
