@import "globals.scss";
.sponsor-modal {
  .MuiDialogTitle-root {
    text-align: center;
    position: relative;

    .MuiTypography-h2 {
      padding-top: 0.5rem;
    }

    button {
      position: absolute;
      top: 0;
      right: 0;
    }
  }

  .MuiDialogContent-root {
    text-align: center;

    .MuiTypography-caption {
      display: block;
      padding-top: 1.5rem;
      padding-bottom: 1rem;
      text-align: center;
      max-width: 300px;
      margin-left: auto;
      color: var(--CoolGrey);
      margin-right: auto;
    }

    .MuiButton-textPrimary {
      margin-top: 1rem;
      text-decoration: underline;
      margin-bottom: 1rem;
      font-weight: bold;
    }
  }

  .sponsors-footer-buttons {
    display: flex;
    justify-content: space-between;
    padding-top: 1.5rem;

    button {
      margin: 1.5rem;
    }
  }

  @media (max-width: $screen-md-min) {
    .sponsors-footer-buttons {
      button {
        min-width: 90px !important;
        font-size: 12px;
      }

      .back-arrow {
        font-size: 15px;
      }
    }
  }

  @media (max-width: $screen-sm-min) {
    .sponsors-footer-buttons {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-top: 1.5rem;

      button {
        margin: 0.5rem;
      }
    }
  }
}
