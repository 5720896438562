@import "globals.scss";
.custom-floor-plan-upload {
  display: flex;
  justify-content: space-between;
  padding: 0px 20px 30px 0px;

  &.in-settings {
    padding: 30px 20px 30px 0px;
  }
  margin: 0px auto;

  .create-floor-plan-link {
    color: #000;
    text-decoration: underline;
    display: flex;
    align-items: flex-end;
    cursor: pointer;

    .MuiButton-root:hover {
      text-decoration: none;
      background-color: transparent;
    }
  }

  .plan-link-size {
    font-size: 14px;
  }

  .upload-btn {
    display: flex;
    align-items: center;
  }
}

.custom-floor-plan-title {
  display: flex;
  align-items: center;

  &-tooltip {
    padding: 20px;
    text-align: left;
  }

  &-header {
    font-weight: bold !important;
    margin-bottom: 1rem !important;
  }

  &-content {
    pointer-events: all;

    > a {
      text-decoration: underline !important;
    }
  }

  .MuiChip-root {
    margin-left: 1rem;
  }
}

.ct-no-floor {
  .MuiButton-text {
    color: #000;
    text-decoration: underline !important;
    cursor: pointer;
  }

  .MuiButton-root:hover {
    text-decoration: underline;
    background-color: transparent;
  }
}

.MuiGrid-container {
  width: 100% !important;
}

.material-icons-outlined {
  margin-right: 10px;
  line-height: 1;
}

.button-color {
  color: #000000;
  border: 1px solid #000000;
}

.remo-alert {
  background-color: #ece8dc;
  font-weight: 500;
  color: #000;
}
