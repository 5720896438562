@import "globals.scss";
.info-block {
  border: 1px solid #34374a;
  border-radius: 5px;
  padding: 15px 30px;
  padding-left: 50px;
  position: relative;
  font-size: 14px;
  line-height: 20px;
  color: white;

  svg {
    position: absolute;
    top: 15px;
    left: 15px;
  }
}
