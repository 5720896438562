@import "globals.scss";
.duration-input {
  display: inline-flex;
  align-items: center;
  height: 55px;
  padding: 0px 13px;
  border-radius: 5px;
  background: var(--White);
  border: 1px solid var(--EventsGrayPrimay);
  box-shadow: 4px 4px 4px 0px #0c38911a;

  svg {
    margin-right: 5px;
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input {
    -moz-appearance: textfield;
    width: 24px;
    box-sizing: border-box;
    text-align: center;
    font-size: 14px;
    position: relative;
    top: 1px;

    &:active,
    &:focus,
    & {
      outline: none;
      border: none;
    }
  }
}
