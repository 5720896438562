@import "globals.scss";
.attendee-list-ctn {
  display: flex;
  flex-direction: column;

  .info-block {
    color: #333;
  }

  .MuiPaper-root {
    box-shadow: none;
    position: relative;
    height: fit-content;
  }

  .MuiTable-root {
    width: 100%;

    .MuiTableHead-root {
      border-radius: 5px;
      border: 1px solid var(--EventsBlueSecondary);

      .MuiTableCell-head {
        z-index: 1;

        .Mui-active {
          color: var(--White);
        }

        .MuiButtonBase-root {
          .MuiTableSortLabel-icon {
            color: var(--White);
          }

          &:hover {
            color: var(--White);
          }
        }
      }
    }

    .MuiTableCell-root {
      font-size: 12px;
      color: "var(--dark)";
    }

    .MuiTableCell-body {
      font-size: 12px;
      font-weight: 400;
    }

    .MuiSvgIcon-root {
      width: 0.8em;
      height: 0.8em;
    }

    .MuiTableCell-footer {
      border: none;
    }
  }

  .attendee-list-toolbar {
    position: absolute;
    bottom: 0;
    z-index: 2;
    padding: 0;

    .reload-button,
    .export-button {
      color: var(--EventsBluePrimary);
    }

    .MuiInputBase-root fieldset {
      border: 1px solid var(--EventsGrayPrimay);
    }
  }
}

.hl-dialog {
  ul {
    max-height: 10rem;
    overflow: auto;
    line-height: 1.45rem;
  }
}
