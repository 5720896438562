@import "globals.scss";
.agenda-item-modal {
  .two-col {
    display: flex;
 justify-content: space-between;

  }
  

  .MuiDialogTitle-root {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    padding: 22px 24px 16px;
  }

  .MuiTextField-root {
    margin-bottom: 1rem;
  }

  .MuiDialogContent-root {
    padding-top: 0.875rem;
    gap: 20px;
    display: flex;
    flex-direction: column;
  }

  .na-duration {
    input[type="time"]::-webkit-calendar-picker-indicator {
      background: none;
    }
  }

  .na-color {
    width: 250px;

    label {
      margin-bottom: 8px;

    }
  }

  .MuiInputLabel-outlined.MuiInputLabel-shrink {
    transform: translate(0);
    margin-bottom: 12px;
    position: relative;
  }

  input.MuiOutlinedInput-input {
    padding: 14px;
  }

  legend {
    display: none;
  }

  .MuiFormHelperText-contained {
    margin-left: 0;
  }

  .MuiDialogActions-root {
    border-top: 1px solid #ededed;
    display: flex;
    justify-content: space-between;
    padding: 1rem 1.5rem 1.5rem;
  }

  .delete-btn {
    &:hover {
      color: #f50057;
    }

    svg {
      margin-right: 7px;
    }
  }

}
