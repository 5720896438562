@import "globals.scss";
.manage-event-modal .MuiDialogContent-root {
  overflow: hidden;
}

.manage-event-container {
  $header-height: 64px;
  $footer-height: 78px;
  $content-padding-top: 2rem;
  $browser-warning-height: 50px;

  padding-bottom: 0;
  height: calc(100vh - #{$header-height});

  .pad-top-40 {
    padding-top: 2.5rem;
  }

  .pad-top-10 {
    padding-top: 10px;
  }

  .pad-top-20 {
    padding-top: 10px;
  }

  .mar-top-20 {
    margin-top: 1.25rem;
  }

  .mar-top-40 {
    margin-top: 2.5rem;
  }

  .mar-top-60 {
    margin-top: 3.75rem;
  }

  .mar-bottom-60 {
    margin-bottom: 3.75rem;
  }

  .mar-bottom-20 {
    margin-bottom: 1.25rem;
  }

  .pad-bottom-20 {
    padding-bottom: 1.25rem;
  }

  .mar-bottom-10 {
    margin-bottom: 10px;
  }

  > .MuiBox-root {
    display: flex;
    position: relative;
    background-color: #ffffff;
    box-sizing: border-box;
    margin-top: 1px;
    height: calc(100vh - #{$header-height});

    .unsupported-browser & {
      min-height: calc(100vh - #{$header-height} - #{$browser-warning-height});
    }

    .me-content {
      flex: 1;
      overflow: auto;
      box-sizing: border-box;
      background: #ffffff;

      .me-tab-content {
        padding-bottom: 78px;
        padding-top: 32px;

        .MuiFormHelperText-contained {
          margin-left: 0;
          font-size: 11px;
          font-weight: 500;
          color: var(--EventsBluePrimary);
          margin-top: 10px;
        }

        .MuiTypography-h2 {
          margin-bottom: 16px;
          display: inline-flex;

          + .MuiTypography-body1 {
            color: var(--EventsLightBlack);
          }
        }

        .MuiTypography-h4 {
          font-weight: 500;
          font-size: 1.125rem;
        }

        .MuiTypography-h5 {
          font-size: 0.875rem;
        }
      }

      .me-actions {
        background-color: #ffffff;
        display: flex;
        position: fixed;
        width: 100%;
        max-width: 860px;
        bottom: 0;
        justify-content: space-between;
        padding: 1rem 2.5rem;
        box-sizing: border-box;
        z-index: 3;
        border-top: 1px solid #ededed;

        .MuiButton-containedPrimary {
          padding: 10px 34px;
        }
      }

      &::-webkit-scrollbar {
        width: 8px;
      }

      &::-webkit-scrollbar-thumb {
        background: #e8e8e8;
        border-radius: 1.25rem;
      }

      &::-webkit-scrollbar-track {
        background: #fff;
      }

      .right-section {
        border-radius: 20px;
        width: 20rem;
        position: absolute;
        left: 100%;
        margin-left: 3rem;
        z-index: 1;
        top: 2rem;
      }
    }

    .me-content.sponsors {
      max-height: calc(100vh - #{$header-height} - #{$content-padding-top});
    }
  }

  .w-350 {
    width: 350px;
  }
}

.manage-event-actions {
  height: 100%;
  margin-right: 16px;

  > button:not(:last-child) {
    margin-right: 16px;
  }
}
