@import "globals.scss";
.agenda.me-content {
  .event-agenda {
    .ea-title {
      display: flex;
      justify-content: space-between;
      margin-bottom: 2.5rem;
      flex-direction: column;
    }

    .info-block {
      color: var(--TextDark);
    }

    .ea-cnt {
      position: relative;
      padding-top: 2.125rem;
      min-height: 10rem;
    }

    #event-agenda-add {
      margin-top: 30px;
      display: flex;
      margin-left: auto;
      margin-right: auto;

      svg {
        height: 16px;
      }
    }

    .eva-item {
      padding: 0;
      position: relative;
      margin-bottom: 1.125rem;
      cursor: pointer;

      .agenda-item-public-ctn {
        margin-bottom: 0;
      }

      .eva-actions {
        position: absolute;
        display: flex;
        flex-direction: column;
        left: -34px;
        bottom: 0;
      }

      .icon-btn-with-tooltip {
        padding: 0;
        width: 1.5rem;
        background-color: var(--White);
        color: var(--EventsBlueSecondary);
        border-radius: 50%;
        margin-top: 1rem;
        transition: opacity 200ms ease-in-out 100ms;
        opacity: 0;
        box-shadow: 2.4px 2.4px 2.4px 0px #0c38911a;

        &.Mui-disabled {
          color: var(--LightGrey);
        }

        svg {
          width: 1.5rem;
          height: 1.5rem;
          fill: currentcolor;
        }
      }

      .eva-delete {
        color: var(--EventsLightBlack);
        background-color: var(--VeryLightGrey);
        margin-top: 0;
        position: absolute;
        top: 15px;
        right: 25px;
        transition: opacity 200ms ease-in-out 100ms,
          transform 150ms ease-in-out 100ms;
        border-radius: 4px;
        height: 1.5rem;

        svg {
          width: 1.25rem;
          height: 1.25rem;
        }
      }

      &:hover {
        .icon-btn-with-tooltip {
          opacity: 1;
        }
      }
    }

    .ea-empty {
      img {
        margin-bottom: 1rem;
      }
      color: var(--EventsLightBlack);
      display: flex;
      flex-direction: column;
      align-items: center;
      font-size: 0.875rem;
      line-height: 1.5rem;
      padding-top: 1.25rem;
      font-weight: 400;
    }

    .ea-loading .page-loading-container {
      position: relative;
    }
  }
}
